<template>
  <div class="">
    <div class="clearfix" v-if="!hideButtons">
      <b-button class="mr-1 mb-1" size="sm" variant="warning" @click="shops.splice(0, shops.length)" v-b-tooltip.left="`${value && value.length || 0} 개 선택됨`">초기화</b-button>
      <!--<b-button class="mr-1 mb-1" size="sm" variant="primary" @click="toggleShop">전체</b-button>-->
      <b-button v-if="shopPresetEditIcon" class="mr-1 mb-1" size="sm" variant="dark" @click="reverseShop">반전</b-button>

      <template v-if="$R('PARTNER_R')">
        <b-dropdown class="mr-1 mb-1" size="sm" variant="light" :no-caret="compress">
          <template v-slot:button-content>
            유형
          </template>
          <b-dropdown-item @click="setShop({shop_type: 'boutique'}, $event)">부티크</b-dropdown-item>
          <b-dropdown-item @click="setShop({shop_type: 'edit_shop'}, $event)">편집샵</b-dropdown-item>
          <b-dropdown-item @click="setShop({shop_type: 'parallel'}, $event)">병행(전체)</b-dropdown-item>
          <b-dropdown-item @click="setShop({shop_type: 'parallel', delivery_type: 'domestic'}, $event)">병행(domestic)</b-dropdown-item>
          <b-dropdown-item @click="setShop({shop_type: 'parallel', delivery_type: ['abroad', 'both']}, $event)">구매대행(abroad,both)</b-dropdown-item>
          <b-dropdown-item @click="setShop({shop_type: ['store', 'balaan']}, $event)">자사, 스토어</b-dropdown-item>
        </b-dropdown>

        <b-dropdown class="mr-1 mb-1" size="sm" variant="light" :no-caret="compress">
          <template v-slot:button-content>
            카테고리
          </template>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['009']}, $event)">WOMEN</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['010']}, $event)">MEN</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['011']}, $event)">골프</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['020']}, $event)">키즈</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['030']}, $event)">리빙</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['040']}, $event)">스포츠</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['050']}, $event)">테크</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['060']}, $event)">펫</b-dropdown-item>
          <b-dropdown-item @click="setShop({categoryWhiteList: ['070']}, $event)">아트</b-dropdown-item>
        </b-dropdown>

        <b-dropdown class="mr-1 mb-1" size="sm" variant="light" :no-caret="compress">
          <template v-slot:button-content>
            {{ compress ? '사용' : '사용여부' }}
          </template>
          <b-dropdown-item @click="setShop({use_yn: 'y'}, $event)">사용</b-dropdown-item>
          <b-dropdown-item @click="setShop({use_yn: 'n'}, $event)">미사용</b-dropdown-item>
        </b-dropdown>

        <b-dropdown class="mr-1 mb-1" size="sm" variant="light" :no-caret="compress">
          <template v-slot:button-content>
            등급
          </template>
          <b-dropdown-item @click="setShop({partnerGrade: 'premium'}, $event)">Premium</b-dropdown-item>
          <b-dropdown-item @click="setShop({partnerGrade: 'smart'}, $event)">Smart</b-dropdown-item>
          <b-dropdown-item @click="setShop({partnerGrade: 'normal'}, $event)">일반</b-dropdown-item>
          <b-dropdown-item @click="setShop({partnerGrade: 'starter1'}, $event)">Starter 1</b-dropdown-item>
          <b-dropdown-item @click="setShop({partnerGrade: 'starter2'}, $event)">Starter 2</b-dropdown-item>
          <b-dropdown-item @click="setShop({partnerGrade: 'none'}, $event)">없음</b-dropdown-item>
        </b-dropdown>

        <b-dropdown class="mr-1 mb-1" size="sm" variant="secondary" :no-caret="compress">
          <template v-slot:button-content>
            {{ compress ? '배송' : '배송타입' }}
          </template>
          <b-dropdown-item @click="setShop({delivery_type: 'abroad'}, $event)">해외</b-dropdown-item>
          <b-dropdown-item @click="setShop({delivery_type: 'domestic'}, $event)">국내</b-dropdown-item>
          <b-dropdown-item @click="setShop({delivery_type: 'both'}, $event)">Both</b-dropdown-item>
        </b-dropdown>

        <b-dropdown class="mr-1 mb-1" size="sm" variant="info" :no-caret="compress">
          <template v-slot:button-content>
            {{ compress ? '연동' : '연동유형' }}
          </template>
          <b-dropdown-item v-for="e in $C.SHOP_FETCH_TYPE" :key="e.code" @click="setShop({fetch_type: e.code}, $event)">{{ e.name }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown class="mr-1 mb-1" size="sm" variant="success" :no-caret="compress">
          <template v-slot:button-content>
            {{ compress ? '원천' : '연동원천' }}
          </template>
          <b-dropdown-item v-for="e in constants.sync_origin" :key="e.value" @click="setShop({sync_origin: e.value}, $event)">{{ e.text }}</b-dropdown-item>
        </b-dropdown>

<!--        <b-dropdown class="mr-1 mb-1" size="sm" variant="primary">
          <template v-slot:button-content>
            {{ compress ? '상품' : '상품유형' }}
          </template>
          <b-dropdown-item @click="setShop({goodsType: 'both'}, $event)">새상품 + 빈티지</b-dropdown-item>
          <b-dropdown-item @click="setShop({goodsType: 'new'}, $event)">새상품</b-dropdown-item>
          <b-dropdown-item @click="setShop({goodsType: 'used'}, $event)">빈티지</b-dropdown-item>
        </b-dropdown>-->

        <b-dropdown class="mr-1 mb-1" size="sm" variant="warning" :no-caret="compress">
          <template v-slot:button-content>
            {{ compress ? '반품' : '반품회수' }}
          </template>
          <b-dropdown-item @click="setShop({logistics: 'balaan'}, $event)">발란물류</b-dropdown-item>
          <b-dropdown-item @click="setShop({logistics: 'direct', shop_type: 'boutique'}, $event)">부티크 직배송</b-dropdown-item>
          <b-dropdown-item @click="setShop({logistics: 'direct', shop_type: 'edit_shop'}, $event)">편집샵 직배송</b-dropdown-item>
        </b-dropdown>

        <template v-if="compress">
          <b-dropdown
              v-for="[k, v] in Object.entries($utils.arr2multi(shopPreset.filter(b=>!~(b.hide||[]).indexOf($S.user.id) && (b.btnShare !== 'team' || b.btnShare === 'team' && b.btnShareTeams.includes($S.user.teamId))), 'btnType'))"
              class="mr-1 mb-1" size="sm" :variant="k" :key="k">
            <template v-slot:button-content>
            </template>
            <template v-for="b in v">
              <b-dropdown-item v-if="!~(b.hide||[]).indexOf($S.user.id) && (b.btnShare !== 'team' || b.btnShare === 'team' && b.btnShareTeams.includes($S.user.teamId)) || shopPresetEditIcon"
                               :key="b._id" @click="shopPresetEditIcon ? show({_id: b._id}) : setShop(b.selShop.join(','), $event)">{{ b.btnName }}
                <i v-if="shopPresetEditIcon" class="ml-1 fa fa-pencil"></i>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>
        <template v-else>
          <b-button class="mr-1 mb-1" size="sm" v-for="b in shopPreset" :key="b._id"
                    v-if="!~(b.hide||[]).indexOf($S.user.id) && (b.btnShare !== 'team' || b.btnShare === 'team' && b.btnShareTeams.includes($S.user.teamId)) || shopPresetEditIcon"
                    :variant="b.btnType"
                    @click="shopPresetEditIcon ? show({_id: b._id}) : setShop(b.selShop.join(','), $event)">{{ b.btnName }}
            <i v-if="shopPresetEditIcon" class="fa fa-pencil"></i>
          </b-button>
        </template>
      </template>

      <!-- 우상단 기능 버튼 -->
      <div class="float-right mb-1">
        <template v-if="$R('SHOP_R')">
          <b-button class="mr-1" size="sm" :variant="compress ? 'dark' : 'light'" title="preset 접기/펴기" @click="toggleCompress">
            <i :class="`fa fa-${compress ? 'expand' : 'compress'}`"></i>
          </b-button>
        </template>
        <b-dropdown v-if="compress" class="" size="sm" variant="light" right>
          <b-dropdown-item :active="extended" @click="extended = !extended"><i class="fa fa-arrows-v"></i> Shop 영역 확장/축소</b-dropdown-item>
          <b-dropdown-item v-if="$R('META_W')" :active="shopPresetEditIcon" @click="shopPresetEditIcon = !shopPresetEditIcon">
            <i class="fa fa-pencil"></i> preset 수정모드
          </b-dropdown-item>
          <b-dropdown-item v-if="$R('META_W')" @click="show({})"><i class="fa fa-plus"></i> preset 추가</b-dropdown-item>
          <b-dropdown-item @click="showInput('main')"><i class="fa fa-filter"></i> Shop ID 로 입력</b-dropdown-item>
          <b-dropdown-item @click="$utils.copyAlert(value.map(e => e.shop_id).join('\n'))"><i class="fa fa-copy"></i> 선택된 Shop ID 복사</b-dropdown-item>
          <b-dropdown-item @click="showHelp()"><i class="fa fa-question"></i> 도움말</b-dropdown-item>
        </b-dropdown>
        <template v-else>
          <b-button class="mr-1" size="sm" :variant="extended ? 'dark' : 'light'" title="shop 영역 확장/축소" @click="extended = !extended">
            <i class="fa fa-arrows-v"></i>
          </b-button>
          <template v-if="$R('META_W')">
            <b-button class="mr-1" size="sm" :variant="shopPresetEditIcon ? 'dark' : 'light'" title="preset 수정모드"
                      @click="shopPresetEditIcon = !shopPresetEditIcon">
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button class="mr-1" size="sm" variant="light" title="preset 추가" @click="show({})"><i class="fa fa-plus"></i></b-button>
          </template>
          <b-button class="mr-1" size="sm" variant="light" title="Shop ID 로 입력" @click="showInput('main')"><i class="fa fa-filter"></i></b-button>
          <b-button class="mr-1" size="sm" variant="light" title="선택된 Shop ID 복사" @click="$utils.copyAlert(value.map(e => e.shop_id).join('\n'))">
            <i class="fa fa-copy"></i>
          </b-button>
          <b-button size="sm" variant="light" title="도움말" @click="showHelp()"><i class="fa fa-question"></i></b-button>
        </template>
      </div>
    </div>

    <v-select v-model="shops" :class="{extended}" :multiple="multiple" :options="shop"
              :placeholder="shop.length ? `전체 ${shop.length} 개 SHOP` : '로딩중...'"></v-select>

    <b-modal title="SHOP Preset 설정" size="lg" v-model="modal.preset" ok-title="저장">
      <div>
        <div class="clearfix mb-1">
          <div class="mt-2 pull-left label-sm">SHOP 을 선택해주세요.</div>
          <div class="pull-right">
            <b-button class="ml-1" size="sm" variant="warning" @click="shopPresetModal.selShop.splice(0, shopPresetModal.selShop.length)">초기화</b-button>
            <b-button class="ml-1" size="sm" variant="outline-primary" @click="copyFromMain()">선택되어있는 값 가져오기</b-button>
            <b-button class="ml-1" size="sm" variant="outline-success" @click="showInput('preset')">Shop ID로 추가하기</b-button>
          </div>
        </div>
        <v-select v-model="shopPresetModal.selShop" multiple :options="shop" placeholder="SHOP 을 선택해주세요."></v-select>
      </div>

      <div class="mt-2">
        <div class="label-sm">버튼 이름을 선택해주세요</div>
        <b-input v-model="shopPresetModal.btnName"></b-input>
      </div>

      <div class="mt-2">
        <div class="label-sm">공유할 범위를 선택해주세요</div>
        <b-form-radio-group class="col-form-label" v-model="shopPresetModal.btnShare" :options="[
          {text: '전체공유', value: 'y'},
          {text: '팀 공유' + (teams.length === 1 ? `(${teams[0].text})` : ''), value: 'team'},
          {text: '개인용', value: 'n'}
        ]"></b-form-radio-group>
      </div>

      <div class="mt-2" v-if="shopPresetModal.btnShare === 'team' && teams.length > 1">
        <div class="label-sm">팀을 선택해주세요</div>

        <b-form-checkbox-group v-model="shopPresetModal.btnShareTeams">
          <b-row class="">
            <!-- 발란 직속, 하위조직 있음 -->
            <b-col v-for="t in teamMap['eB8qOVqxzK'].subtree.filter(e => e.subtree)" :key="t.id" xl="3" md="4" sm="6" cols="12" class="mt-2 clearfix">
              <b-form-checkbox :value="t.id">{{t.text}}</b-form-checkbox><br/>
              <div v-for="e in (t.subtree || [])" :key="e.id">
                <b-form-checkbox :value="e.id" class="ml-3">
                  {{ e.text }}
                </b-form-checkbox>
              </div>
            </b-col>

            <!-- 발란 직속, 하위조직 없음 -->
            <b-col xl="3" md="4" sm="6" cols="12" class="mt-2 clearfix">
              <div v-for="t in teamMap['eB8qOVqxzK'].subtree.filter(e => !e.subtree)" :key="t.id">
                <b-form-checkbox :value="t.id" class="">
                  {{ t.text }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>

          <hr />

          <!-- not 발란(협력사 등) -->
          <b-row class="">
            <b-col v-for="t in teams.filter(e => !e.balaan && !e.old && !e.parentId)" :key="t.id" xl="3" md="4" sm="6" cols="12" class="mt-2 clearfix">
              <b-form-checkbox :value="t.id">{{t.text}}</b-form-checkbox><br/>
              <div v-for="e in (t.subtree || [])" :key="e.id">
                <b-form-checkbox :value="e.id" class="ml-3">
                  {{ e.text }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </div>

      <div class="mt-2">
        <div class="label-sm">버튼 색상을 선택해주세요</div>
        <b-button class="mr-1 mb-1" size="sm" v-for="c in ['primary','secondary','success','warning','danger','info','light','dark']" :key="c"
                  :variant="`${shopPresetModal.btnShare === 'n' ? 'outline-' : ''}${c}`"
                  @click="setBtnType(`${shopPresetModal.btnShare === 'n' ? 'outline-' : ''}${c}`)">{{ c }}
        </b-button>
      </div>

      <div class="mt-2">
        <div class="label-sm">선택한 버튼의 형태를 확인해주세요</div>
        <b-button size="sm" class="mr-1 mb-2" :variant="shopPresetModal.btnType">{{ shopPresetModal.btnName }}</b-button>
      </div>

      <b-checkbox v-model="shopPresetModal.hideOnMe">이 버튼을 나에게서 숨깁니다.</b-checkbox>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="$R('DEV')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: shopPresetModal})">
          JSON
        </b-button>
        <b-button v-if="shopPresetModal._id" variant="success" @click="updateShopPreset">
          수정
        </b-button>
        <b-button v-else variant="primary" @click="addShopPreset">
          저장
        </b-button>
        <b-button v-if="shopPresetModal._id" variant="danger" @click="deleteShopPreset">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="SHOP ID 로 Shop 선택" size="lg" v-model="modal.input">
      Shop ID 를 입력해주세요.
      <b-textarea v-model="shop_ids" placeholder="ex) 1, 2, 3" rows="5"></b-textarea>
      <template v-if="input_target === 'main'">
        <hr/>
        현재 선택된 Shop ID
        <b-btn variant="light" size="sm" @click="$utils.copyAlert(selectedIdsText)">복사하기</b-btn>
        <b-textarea v-model="selectedIdsText" readonly rows="5"></b-textarea>
      </template>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="setShopIds()">
          적용
        </b-button>
        <b-button variant="success" @click="setShopIds(true)">
          추가
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="도움말" size="lg" v-model="modal.help">
      <div class="mb-2" v-if="$R('SHOP_R')">
        <span class="btn btn-light btn-sm"><i class="fa fa-compress"></i></span> : SHOP Preset 버튼들을 축소형태로 볼 수 있습니다
      </div>
      <div class="mb-2">
        <span class="btn btn-light btn-sm"><i class="fa fa-arrows-v"></i></span> : 선택된 SHOP 들의 영역을 확장, 축소할 수 있습니다
      </div>
      <div class="mb-2" v-if="$R('META_W')">
        <div class="mb-2">
          <span class="btn btn-light btn-sm"><i class="fa fa-pencil"></i></span> : SHOP Preset 수정모드로 전환합니다
        </div>
        <div class="mb-2">
          <span class="btn btn-light btn-sm"><i class="fa fa-plus"></i></span> : SHOP Preset 을 추가합니다
        </div>
      </div>
      <div class="mb-2">
        <span class="btn btn-light btn-sm"><i class="fa fa-filter"></i></span> : SHOP ID 를 직접 입력해서 추가 가능합니다
      </div>
      <div class="mb-2">
        <span class="btn btn-light btn-sm"><i class="fa fa-copy"></i></span> : 현재 입력되어있는 SHOP ID 를 복사합니다
      </div>
      <div class="mb-2">
        SHOP Preset 을 Shift Key 를 누르면서 Click : 기존 SHOP 에 추가합니다 (OR 조건)
      </div>
      <div class="mb-2">
        SHOP Preset 을 Ctrl Key 를 누르면서 Click : 기존 SHOP 과 공통인 SHOP 만 남겨둡니다 (AND 조건)
      </div>
      <div class="mb-2">
        SHOP Preset 을 Ctrl Key + Shift Key 를 누르면서 Click : 기존 SHOP 과 공통인 SHOP 만 제거합니다
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "ShopPreset",
  model: {prop: 'value', event: 'change'},
  props: {
    value: null, // multiple 일 땐 Array, multiple 이 아닐 땐 Object 라서 이렇게 지정
    hideButtons: Boolean,
    multiple: {type: Boolean, default: true},
    shopPresetOption: Object
  },
  data() {
    return {
      shop: [],
      shopMap: {},
      shopPreset: [],
      shopPresetModalBase: {
        _id: '',
        selShop: [],
        btnName: '버튼명',
        btnShare: 'n',
        btnShareTeams: [],
        btnType: 'light',
        hideOnMe: false,
      },
      TEAMS: [], // this.$C.TEAMS
      teamMap: {}, // this.$utils.arr2map(this.$C.TEAMS, 'value'),
      teams: [], // this.$C.TEAMS.filter(e => !e.disabled),
      form: {
        teams: [],
      },
      shopPresetModal: {selShop: [],},
      shopPresetEditIcon: false,
      compress: false,
      extended: false,
      shop_ids: '',
      selectedIdsText: '',
      input_target: 'main',
      modal: {preset: false, input: false, help: false},
      constants: {
        sync_origin: [ // Shop.vue 와 싱크 필요
          {text: '자동(Feed)', value: 'feed'},
          {text: '파트너센터', value: 'partner'},
          {text: '위즈파스타', value: 'partner_wizfasta'},
          {text: 'EC모니터', value: 'partner_ecm'},
          {text: '사방넷', value: 'partner_sabangnet'},
          {text: '스토어봇', value: 'partner_storebot'},
          {text: '더망고', value: 'partner_themango'},
          {text: '샵플링', value: 'partner_shopling'},
          {text: '이지어드민', value: 'partner_ezadmin'},
          {text: '세원셀릭', value: 'partner_sewonsellic'},
          {text: '샵링커', value: 'partner_shoplinker'},
          {text: '파트너API', value: 'partner_api'},
          {text: '수동(Mapped)', value: 'mapped'},
          {text: '미정', value: 'unknown'},
        ],
      }
    }
  },
  watch: {
    shopPresetModal: {
      deep: true,
      handler(v) {
        this.shopPresetModal.btnType = (v.btnShare === 'n' ? 'outline-' : '') + this.shopPresetModal.btnType.replace('outline-', '');
      },
    },
  },
  computed: {
    shops: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  async created() {
    this.makeTeamTree();

    // let meta = await this.$api.getMeta('shop');
    // if (!meta) return;
    //
    // this.$utils.getStatus(this.$options.name, this, 'compress');
    //
    // this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    // this.shop.forEach(s => {
    //   s.value = s.shop_id;
    //   s.label = `${s.use_yn !== 'y' ? '[미사용]' : ''} ${s.shop_id}. ${s.boutique}`;
    //   this.shopMap[s.shop_id] = s;
    // });

    this.$utils.getStatus(this.$options.name, this, 'compress');

    // let meta = await this.$api.getMeta('shop');
    // if (!meta) return;
    await this.$api.getAllMeta();
    this.shop = this.$S.m.shop.list.slice();
    this.shopMap = this.$S.m.shop.map;

    if (this.shopPresetOption && this.shopPresetOption.excludeSpecialShop) {
      this.shop = this.shop.filter(e => ![0, 204, 2806].includes(e.shop_id));
    }
    this.shopPreset = await this.$api.getShopPreset();
  },
  methods: {
    show(params) {
      this.modal.preset = true;
      if (params._id) {
        this.shopPresetModal = this.$utils.clone(this.shopPreset.filter(e => e._id === params._id)[0]);
        this.shopPresetModal.selShop = this.shopPresetModal.selShop.map(e => this.shopMap[e]).filter(e => e).select('shop_id, label, value');
      } else {
        this.shopPresetModal = this.$utils.clone(this.shopPresetModalBase);
      }
    },
    showInput(target) {
      this.input_target = target;
      this.modal.input = true;
      this.shop_ids = '';
      this.selectedIdsText = this.value.map(e => e.shop_id).join('\n');
    },
    async hide() {
      this.modal.preset = false;
      this.shopPreset = await this.$api.getShopPreset();
    },
    showHelp() {
      this.modal.help = true;
    },
    setShop(obj, event) {
      let shops;
      if (typeof (obj) === 'string') {
        const shopSet = obj.split(',').map(e => +e);
        shops = this.shop.filter(e => e.shop_id.in(shopSet));
      } else {
        let shop = this.shop;
        Object.entries(obj).forEach(([k, v]) => {
          if (Array.isArray(v)) {
            shop = shop.filter(e => {
              if (Array.isArray(e[k])) {
                return v.some(elem => e[k].includes(elem));
              } else {
                return v.includes(e[k]);
              }
            });
          } else {
            shop = shop.filter(e => e[k] === v);
          }
        });
        shops = shop;
      }

      if (event && event.ctrlKey && event.shiftKey) { // 기존 shops 에서 선택된 shops 를 제거 ex) 1,2,3 선택중 ctrl+shift 로 3,4 를 선택하면 1,2 만 남는다.
        const excludedShops = this.value.filter(e => !shops.includes(e)); // 새로 선택한 shops 에 포함된 shop 을 제거
        this.value.splice(0, this.value.length, ...excludedShops);
      } else if (event && event.shiftKey) { // 기존 shops 에 추가
        shops = shops.filter(e => !this.value.includes(e)); // 기존에 없는 shop 만
        this.value.splice(0, this.value.length, ...this.value.concat(shops));
      } else if (event && event.ctrlKey) { // 기존 shops 와 and 조건
        shops = shops.filter(e => this.value.includes(e)); // 기존에 있는 shop 만
        this.value.splice(0, this.value.length, ...shops);
      } else {
        this.value.splice(0, this.value.length, ...shops);
      }
    },
    toggleShop() {
      if (this.value.length === this.shop.length) {
        this.value.splice(0, this.value.length);
      } else {
        this.value.splice(0, this.value.length, ...this.shop.map(e => e));
      }
    },
    reverseShop() {
      this.value.splice(0, this.value.length, ...this.shop.filter(e => !~this.value.indexOf(e)));
    },
    setBtnType(t) {
      this.shopPresetModal.btnType = t;
    },
    async addShopPreset() {
      if (!this.shopPresetModal.btnName) return alert('버튼 이름을 입력해주세요');
      if (!this.shopPresetModal.selShop.length) return alert('SHOP을 선택해주세요');
      let j = await this.$api.postJson('/meta/shopPreset', {type: 'add', ...this.shopPresetModal, selShop: this.shopPresetModal.selShop.set('shop_id')});
      if (j) {
        // alert ('추가되었습니다');
        this.hide();
      }
    },
    async updateShopPreset() {
      if (!this.shopPresetModal.btnName) return alert('버튼 이름을 입력해주세요');
      if (!this.shopPresetModal.selShop.length) return alert('SHOP을 선택해주세요');
      let j = await this.$api.postJson('/meta/shopPreset', {type: 'update', ...this.shopPresetModal, selShop: this.shopPresetModal.selShop.set('shop_id')});
      if (j) {
        // alert ('수정되었습니다');
        this.hide();
      }
    },
    async deleteShopPreset() {
      if (!confirm('정말로 삭제하시겠습니까?')) return;
      let j = await this.$api.postJson('/meta/shopPreset', {type: 'delete', _id: this.shopPresetModal._id});
      if (j) {
        alert('삭제되었습니다');
        this.hide();
      }
    },
    copyFromMain() {
      this.shopPresetModal.selShop.splice(0, this.shopPresetModal.selShop.length, ...this.value);
    },
    setShopIds(append) {
      if (!this.shop_ids.trim()) return alert('Shop ID 를 하나 이상 입력해주세요');
      let shop_ids = this.shop_ids.trim().split(/\D+/g).map(e => +e);
      if (append) {
        shop_ids = Array.from(new Set(shop_ids.concat(this.value.map(e => e.shop_id))));
      }
      let shops = shop_ids.map(e => this.shopMap[e]).filter(e => e);
      if (this.input_target === 'preset') {
        this.shopPresetModal.selShop.splice(0, this.shopPresetModal.selShop.length, ...shops);
      } else {
        this.value.splice(0, this.value.length, ...shops);
      }
      this.modal.input = false;
    },
    toggleCompress() {
      this.compress = !this.compress;
      this.$utils.setStatus(this.$options.name, this, 'compress');
    },
    makeTeamTree() {
      const TEAMS = this.TEAMS = this.$utils.clone(this.$C.TEAMS);
      this.teamMap = this.$utils.arr2map(TEAMS, 'value');
      this.teams = TEAMS.filter(e => !e.disabled);

      TEAMS.forEach(e => {
        if (e.parentId) {
          const parent = this.teamMap[e.parentId];
          (parent.subtree = parent.subtree || []).push(e);
        }
      });
      TEAMS.forEach(e => {
        let me = e;
        let padding = 0;
        while (me.parentId && this.teamMap[me.parentId]) {
          padding++;
          me = this.teamMap[me.parentId];
        }
        e.padding = padding;
      });
    },
  },

}
</script>
