<template>
  <div>
    <small>카테고리 지정 및 저장(끝의 * 표시는 최종카테고리를, → 표시는 하위 카테고리가 있음을 의미합니다)</small><br/>

    <b-button-group>
      <b-button
        v-for="categoryDoc in rootCategories"
        :disabled="disabled"
        :variant="(selectedCategoryCode || '').startsWith(categoryDoc.category) ? 'dark' : 'light'"
        :key="categoryDoc.category"
        @click="selectCategory(categoryDoc.category)"
      >
        {{ categoryDoc.category_nm }}
      </b-button>
    </b-button-group>

    <b-row class="mb-2">
      <b-col>
        <small>2차 카테고리</small>
        <b-form-select :disabled="disabled" :select-size="10" v-model="selectedCategoryPerLevel[1]" :options="categoryOptionsPerLevel[1]" @change="selectCategory"></b-form-select>
      </b-col>
      <b-col>
        <small>3차 카테고리</small>
        <b-form-select :disabled="disabled" :select-size="10" v-model="selectedCategoryPerLevel[2]" :options="categoryOptionsPerLevel[2]" @change="selectCategory"></b-form-select>
      </b-col>
      <b-col>
        <small>4차 카테고리</small>
        <b-form-select :disabled="disabled" :select-size="10" v-model="selectedCategoryPerLevel[3]" :options="categoryOptionsPerLevel[3]" @change="selectCategory"></b-form-select>
      </b-col>
      <b-col>
        <small>5차 카테고리</small>
        <b-form-select :disabled="disabled" :select-size="10" v-model="selectedCategoryPerLevel[4]" :options="categoryOptionsPerLevel[4]" @change="selectCategory"></b-form-select>
      </b-col>
    </b-row>

    <v-select
      placeholder="검색"
      :disabled="disabled"
      :options="categories.map(i => ({ label: `${i.category} (${getCategoryPath(i.category)})`, value: i.category }))"
      :reduce="option => option.value"
      v-model="selectedCategoryCode"
      @input="event => selectCategory(event ? event.value : null)"
    ></v-select>

    <b-row class="mt-2" align-h="between">
      <b-col cols="auto">
        <b-button :disabled="disabled" @click="selectCategory(null)">초기화</b-button>
      </b-col>
      <b-col cols="auto">
        <div v-if="selectedCategoryCode">
          <span class="font-weight-bold">{{ getCategoryPath(selectedCategoryCode) }}</span>
          카테고리 선택됨
        </div>
        <div v-else>
          카테고리가 선택되지 않음
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'CategoryDepthPreset',
  props: {
    value: null,
    disabled: Boolean,
  },
  data() {
    return {
      categories: [],
      categoryMap: {},
      rootCategories: [],
      categoryOptionsPerLevel: [[], [], [], [], []],
      selectedCategoryPerLevel: [null, null, null, null, null],
      selectedCategoryCode: null,
    };
  },
  async created() {
    const meta = await this.$api.getMeta('category');
    if (!meta) return;

    const { category } = meta;

    this.categories = category;
    this.categoryMap = this.categories.reduce((prev, cur) => {
      prev[cur.category] = cur;
      return prev;
    }, {});
    this.rootCategories = this.categories.filter(i => i.category.length === 3);
  },
  methods: {
    getCategoryPath(categoryCode) {
      const paths = [];

      for (let i = 0; i < categoryCode.length; i += 3) {
        const segment = categoryCode.substring(0, i + 3);

        paths.push(this.categoryMap[segment].category_nm);
      }

      return paths.join(' > ');
    },
    selectCategory(categoryCode) {
      if (categoryCode === undefined) return;
      // But accept null or empty string value

      this.selectedCategoryCode = categoryCode;
      this.$emit('input', this.selectedCategoryCode);

      for (let level = 1; level < 5; level++) {
        if (!this.selectedCategoryCode) {
          this.categoryOptionsPerLevel[level] = [];
          this.selectedCategoryPerLevel[level] = null;
          continue;
        }

        const upperCategoryLength = level * 3;
        const upperCategory = this.selectedCategoryCode.substring(0, upperCategoryLength);

        const targetCategories = this.categories.filter(i => i.category.startsWith(upperCategory) && i.category.length === upperCategoryLength + 3);
        this.categoryOptionsPerLevel[level] = targetCategories.map(i => ({ text: `${i.category_nm} ${i.final ? '*' : '→'}`, value: i.category }));
        this.selectedCategoryPerLevel[level] = this.selectedCategoryCode.substring(0, (level + 1) * 3);
      }
    },
  },
};
</script>
